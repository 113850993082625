<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue';
interface Props {
  scrollerContainer?: string | null;
  hasBackground?: boolean;
  isStartBackgroundDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  scrollerContainer: null,
  hasBackground: true,
  isStartBackgroundDisabled: false,
});

const { $gsap } = useNuxtApp();

// Define proper GSAP types
let animation1: gsap.core.Tween | null = null;
let animation2: gsap.core.Tween | null = null;

const animate = () => {
  const selector = $gsap.utils.selector('[data-star-background]');

  // Kill existing animations if they exist
  if (animation1) {
    animation1.kill();
  }
  if (animation2) {
    animation2.kill();
  }

  animation1 = $gsap.to(selector('[data-stars-layer="1"]'), {
    scrollTrigger: {
      trigger: '[data-star-background]',
      start: 'top 80%',
      scroller: props.scrollerContainer || window,
      toggleActions: 'play pause resume pause',
    },
    backgroundPosition: '55px -2000px',
    duration: 500,
    ease: 'none',
    repeat: -1,
  });

  animation2 = $gsap.to(selector('[data-stars-layer="2"]'), {
    scrollTrigger: {
      trigger: '[data-star-background]',
      start: 'top 80%',
      scroller: props.scrollerContainer || window,
      toggleActions: 'play pause resume pause',
    },
    backgroundPosition: '0px -2000px',
    duration: 500,
    ease: 'none',
    repeat: -1,
  });
};

const cleanup = () => {
  if (animation1) {
    animation1.kill();
    animation1 = null;
  }
  if (animation2) {
    animation2.kill();
    animation2 = null;
  }
};

const { animationsEnabled } = storeToRefs(useAccessibilityStore());

onMounted(() => {
  if (animationsEnabled.value && !props.isStartBackgroundDisabled) {
    animate();
  }
});

onBeforeUnmount(() => {
  cleanup();
});

watch(animationsEnabled, (newValue) => {
  if (newValue) {
    // Initialize or resume animations
    if (!animation1 || !animation2) {
      animate();
    } else {
      animation1.resume();
      animation2.resume();
    }
  } else {
    // Pause existing animations
    animation1?.pause();
    animation2?.pause();
  }
});

const startBackgroundImage = computed(() => {
  return cva('', {
    variants: {
      hasBackground: {
        true: "bg-[url('/images/onboarding-background.svg')] bg-cover bg-bottom bg-no-repeat",
      },
    },
  })({
    hasBackground: props.hasBackground,
  });
});

const disableClasses = computed(() => {
  return props.isStartBackgroundDisabled ? 'bg-none [&_[data-stars-layer]]:hidden' : '';
});
</script>

<template>
  <div data-star-background class="relative" :class="[startBackgroundImage, disableClasses]">
    <div data-stars-layer="1" class="absolute inset-0 bg-[url('/images/stars.svg')] bg-[65px_100px] bg-repeat" />
    <div data-stars-layer="2" class="absolute inset-0 bg-[url('/images/stars.svg')] bg-repeat" />
    <div class="relative">
      <slot />
    </div>
  </div>
</template>
